import React, { useContext } from "react";
import { MdPhoneInTalk } from "react-icons/md";
import { GlobalDataContext } from "../../context/context";




const BlockAbout4 = ({ title, img1 }) => {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className="w-full h-auto py-10">
            <div className="w-full lg:h-[38vh] h-[70vh]  flex lg:flex-row flex-col">
                <div
                    className="lg:w-[60%] w-[90%] rounded-r-full h-full border-white border-t-8 border-b-8 border-r-8 shadow-xl relative "
                    style={{
                        backgroundImage: `url("${img1}")`,
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="w-20 h-20 bottom-[27%] lg:bottom-[33%] -right-8 absolute flex justify-center items-center">
                        <div className="w-14 h-14 rounded-full bg-secondary  animate-ping transition ease-in-out duration-700"></div>
                        <div className="w-12 h-12 rounded-full bg-secondary  animate-ping transition ease-in-out duration-100 absolute"></div>
                        <a
                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                            className='flex items-center justify-center absolute border border-black w-auto'
                        >
                            <div className="w-16 rounded-full h-16 border-white border-[3px] bg-2 absolute flex justify-center items-center">
                                <MdPhoneInTalk className=" text-[30px]" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="lg:w-2/5 w-full flex flex-col justify-center text-center md:text-start lg:pl-8 pl-2 py-4">
                    <h1 className="lg:text-[46px] text-[36px] font-extrabold pb-3 text-secondary">{title}</h1>
                    <div className="w-full bg-2 h-1 flex">
                        <spam className="w-auto h-auto">
                            <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                className='flex items-center justify-center absolute border border-black w-auto'>
                            </a>
                        </spam>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default BlockAbout4;